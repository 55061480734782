import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import parse from "html-react-parser";
import breakpoints from '../../utils/breakpoints';
import Cta from '../cta';

const Container = styled.div`
  position: fixed;
  z-index: var(--z-cookie-banner);
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--brand-gray);
  padding: 4rem var(--grid-padding);
  transform: ${props => props.active ? 'none' : 'translateY(100%)'};
  opacity: ${props => props.active ? '1' : '0'};
  pointer-events: ${props => props.active ? 'initial' : 'none'};
  transition: opacity 0.8s ease-in-out, transform 0.6s ease-in-out;
  color: var(--brand-white);

  button {
    position: relative;
    cursor: pointer;
    padding: 1.4rem 3.2rem;
    line-height: 1;
    white-space: nowrap;

    span {
      text-overflow: initial;
    }

    &[data-theme="outline"] {
      background-color: var(--brand-white);
      color: var(--brand-gray);
    }
  }
`;

const Toggles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 3.2rem;
  column-gap: 6.4rem;
  margin-bottom: 4.5rem;

  @media ${breakpoints.tabletSm} {
    flex-flow: row wrap;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.4rem;
  flex-flow: column nowrap;

  @media ${breakpoints.tabletSm} {
    flex-flow: row wrap;
  }

  @media ${breakpoints.desktop} {
    flex-wrap: nowrap;
  }
`;

const Headline = styled.h2`
  display: block;
  width: 100%;
  padding-bottom: 2.4rem;
  margin: 0 0 2.4rem;
  border-bottom: 2px solid var(--brand-medium-gray);
  font-family: var(--font-secondary);
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 4rem;
`;

const CookieMessage = styled.div`
  display: block;
  font-family: var(--font-secondary);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  opacity: 0.3;

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
    text-decoration-color: currentColor;
    transition: text-decoration-color 0.4s ease;

    &:hover,
    &:focus {
      text-decoration-color: transparent;
    }
  }
`;

const ToggleOption = styled.div`
  display: flex;
  gap: 2.4rem;
  justify-content: space-between;
  width: 100%;
  font-family: var(--font-secondary);

  @media ${breakpoints.tabletSm} {
    width: fit-content;
  }

  h3 {
    margin: 0;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.6rem;
  }

  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
  }
`;

const ToggleSwitch = styled.span`
  display: inline-block;
  width: 5.6rem;
  height: 2.8rem;
  position: relative;
  cursor: pointer;
  background-color: var(--brand-gray);
  border: 1px solid #ffffff;
  border-radius: 10rem;
  transition: all 0.4s ease;

  &::before {
    content: "";
    position: absolute;
    height: 2rem;
    width: 2rem;
    left: 0.3rem;
    bottom: 0.3rem;
    background-color: #ffffff;
    transition: transform 0.4s ease, background 0.4s ease;
    border-radius: 50%;
  }

  &.active,
  &.disabled {
    &::before {
      transform: translateX(2.8rem);
      background-color: var(--brand-gray);
    }
  }

  &.active {
    background: linear-gradient(90deg, #E91711 5.69%, #EFD608 94.31%);

    &::before {
      background-color: var(--brand-gray);
    }
  }

  &.disabled {
    background: var(--brand-medium-gray);
    pointer-events: none;
  }
`;

const FirstView = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  @media ${breakpoints.tabletSm} {
    row-gap: 0;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  ${CookieMessage},
  ${ButtonWrapper} {
    @media ${breakpoints.tablet} {
      max-width: calc(50% - 2.4rem);
    }
  }

  ${CookieMessage} {
    margin-bottom: 2.4rem;
  
    @media ${breakpoints.tablet} {
      margin-bottom: 0;
    }
  }

  ${ButtonWrapper} {
    @media ${breakpoints.tablet} {
      max-width: calc(50% - 2.4rem);
      justify-content: flex-end;
    }
  }
`;

const PrefView = styled.div`
  display: flex;
  flex-flow: column nowrap;

  @media ${breakpoints.tabletSm} {
    flex-flow: row wrap;
  }

  ${CookieMessage} {
    margin-bottom: 2.4rem;
  }

  ${ButtonWrapper} {
    width: 100%;
  }
`;

const SecondaryCta = styled.button`
  font-family: var(--font-secondary);
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  font-size: 1.6rem;
  line-height: 1;
  transition: color 0.5s var(--ease-soft), background-color 0.5s var(--ease-soft);
  text-decoration: none;
  font-weight: 500;
  backface-visibility: hidden;
	max-width: 100%;
	overflow: hidden;
  padding: 1.4rem 2.4rem;
  color: var(--brand-medium-gray);
  border: 1px solid var(--brand-medium-gray);

  :hover,
  :focus {
    background-color: var(--brand-light-gray);
    color: var(--brand-gray);
  }
`

export default function CookieBanner() {
  const [active, setActive] = useState(false);
  const [prefView, setPrefView] = useState(false);

  const [options, setOptions] = useState([{
    id: 'functional',
    active: true,
    necessary: true,
    title: 'Strictly Necessary',
    description: "Always active, can’t be disabled",
  }, {
    id: 'performance',
    active: false,
    necessary: false,
    title: 'Performance',
    description: "Vimeo",
  }, {
    id: 'marketing',
    active: false,
    necessary: false,
    title: 'Marketing',
    description: "Google Analytics",
  }])

  const data = useStaticQuery(graphql`
    query cookie {
      cms {
    globalSet(handle: "footer") {
          ... on cms_GlobalSet_Footer {
        cookie_message
      }
    }
  }
}
`)

  const { cookie_message } = data.cms.globalSet;

  const toggleOption = (index, forceState = null) => {
    const option = options[index]
    if (option.necessary) return

    option.active = forceState !== null ? forceState : !option.active
    const tmp = options
    tmp[index] = option
    setOptions([...tmp])
  }

  const dispatch = () => {
    setActive(false)
    setPrefView(false)
    window?.Cookiebot?.submitCustomConsent(options[1].active, false, options[2].active)
    window?.Cookiebot?.runScripts()
  }
  const onAccept = () => {
    options.forEach((_, i) => toggleOption(i, true))
    dispatch()
  }
  const onDecline = () => {
    options.forEach((_, i) => toggleOption(i, false))
    dispatch()
  }
  const showPref = () => {
    setPrefView(true)
  }

  useEffect(() => {
    const onDialogDisplay = () => {
      setActive(true)
    }

    if (typeof window?.Cookiebot !== 'undefined') {
      if (!window?.Cookiebot?.hasResponse) {
        setActive(true)
      }

      window.addEventListener('CookiebotOnDialogDisplay', onDialogDisplay)
    }

    return () => {
      window.removeEventListener('CookiebotOnDialogDisplay', onDialogDisplay)
    }
  }, []);

  return (
    <Container active={active}>
      {!prefView ? (
        <FirstView>
          <Headline>This site uses cookies to improve your experience.</Headline>
          <CookieMessage>
            {parse(cookie_message || '')}
          </CookieMessage>
          <ButtonWrapper>
            <Cta
              data-theme="outline"
              as="button"
              onClick={onAccept}
            >
              Accept All
            </Cta>
            <SecondaryCta
              type="button"
              onClick={onDecline}
            >
              Deny All
            </SecondaryCta>
            <SecondaryCta
              type="button"
              onClick={showPref}
            >
              Change Cookie Preferences
            </SecondaryCta>
          </ButtonWrapper>
        </FirstView>
      ) : (
        <PrefView>
          <Headline>Cookie Preferences</Headline>
          <CookieMessage>
            {parse(cookie_message || '')}
          </CookieMessage>
          <Toggles>
            {options.map((option, index) => (
              <ToggleOption key={option.id}>
                <div>
                  <h3>{option.title}</h3>
                  <p>{option.description}</p>
                </div>
                <ToggleSwitch
                  onClick={() => toggleOption(index)}
                  className={`${option.active ? 'active' : ''} ${option.necessary ? 'disabled' : ''} `}
                />
              </ToggleOption>
            ))}
          </Toggles>
          <ButtonWrapper>
            <Cta
              data-theme="outline"
              as="button"
              onClick={onAccept}
            >
              Accept All Cookies
            </Cta>
            <SecondaryCta
              type="button"
              onClick={dispatch}
            >
              Save Preferences
            </SecondaryCta>
          </ButtonWrapper>
        </PrefView>
      )}
    </Container>
  )
}